export const getShowEditViewLocalization = (state) => {
	return state.showEditViewLocalization

}
export const getLanguageList = (state) => {
    return state.languageList.data
}

export const getLanguageListLinks = (state) => {
    return state.languageList.links
}

export const getLanguageListMeta = (state) => {
    return state.languageList.meta
}
