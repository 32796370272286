export const storeCategory = ({ commit, dispatch },{payload,context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-category/'+payload.id,payload).then((response) => {
        return response;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        context.errors.category_name = false;
        context.errors.slug = false;
        context.errors.slug_message = error.response.data.slug ? error.response.data.slug[0] : '';
        context.errors.category_message = error.response.data.category_name ? error.response.data.category_name[0] : '';
        context.isAddCategory = false;
        context.gIndicator('stop');
        return false;
    });
}
export const getCategoryList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-categories' + query).then((response) => {
        commit('setCategories', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
}
export const updateCategoryStatus = ({ commit, dispatch },{payload}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-categories-status/'+payload.id,payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
}
export const getCategoryById = ({ commit, dispatch },{payload}) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-get-category/'+payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
}
export const storeUnit = ({ commit, dispatch },{payload,context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-unit/'+payload.id,payload).then((response) => {
        return response;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        context.errors.type = false;
        context.errors.unit_message = error.response.data.unit ? error.response.data.unit[0] : '';
        context.isAddUnit = false;
        context.gIndicator('stop');
        return false;
    });
}
export const getUnitList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-units' + query).then((response) => {
        commit('setUnits', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
}
export const updateUnitStatus = ({ commit, dispatch },{payload}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-units-status/'+payload.id,payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
}
export const getUnitById = ({ commit, dispatch },{payload}) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-get-unit/'+payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
}
export const storeSubCategory = ({ commit, dispatch },{payload,context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-sub-category/'+payload.id,payload).then((response) => {
        return response;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        context.errors.sub_category_name = false;
        context.errors.slug = false;
        context.errors.slug_message = error.response.data.slug ? error.response.data.slug[0] : '';
        context.errors.sub_category_message = error.response.data.sub_category_name ? error.response.data.sub_category_name[0] : '';
        context.isAddSubCategory = false;
        context.gIndicator('stop');
        return false;
    });
}
export const getSubCategoryList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-sub-categories' + query).then((response) => {
        commit('setSubCategories', response.data);
        return true;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
}
export const updateSubCategoryStatus = ({ commit, dispatch },{payload}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-sub-categories-status/'+payload.id,payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
}
export const getSubCategoryById = ({ commit, dispatch },{payload}) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/master-data-get-sub-category/'+payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    });
}