export const getImportedLanguageCodeList = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/imported-language-code-list').then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const getLanguageNameBySlug = ({ commit, dispatch },payload) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-language-name-by-slug/' + payload.slug).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const setLanguage = ({ commit, dispatch },payload) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/set-language-name',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const getLanguageTranslationList = ({ commit, dispatch },query='') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-language-translation-list'+query).then((response) => {
        commit('setLanguageList', response.data);
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const editTranslation = ({ commit, dispatch },payload) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/update-language-translation-value',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const generateJSONFile = ({ commit, dispatch },payload) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/generate-json-file',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const copyJSONFile = ({ commit, dispatch },payload) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/copy-json-file/'+payload.payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const exportFile = ({ commit, dispatch },payload) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/export-file',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}