import { createStore } from 'vuex'
import state from "./state.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";


import auth from "../app/auth/vuex/index.js";
import adminProfile from "../app/adminProfile/vuex/index.js";
import adminDashboard from "../app/adminDashboard/vuex/index.js";
import roleAndPermission from "../app/roleAndPermission/vuex/index.js";
import orders from "../app/orders/vuex/index.js";
import myTasks from "../app/myTasks/vuex/index.js";
import exhibitions from "../app/exhibitions/vuex/index.js";
import users from "../app/users/vuex/index.js";
import artLibrary from "../app/artLibrary/vuex/index.js";
//import photoLibrary from "../app/photoLibrary/vuex/index.js";
//import frameLibrary from "../app/frameLibrary/vuex/index.js";
//import designLibrary from "../app/designLibrary/vuex/index.js";
import adminCrowdFunding from "../app/adminCrowdFunding/vuex/index.js";
import financeAndAudit from "../app/financeAndAudit/vuex/index.js";
import promotions from "../app/promotions/vuex/index.js";
import referrals from "../app/referrals/vuex/index.js";
import feedbacks from "../app/feedbacks/vuex/index.js";
import errorsLog from "../app/errorsLog/vuex/index.js";
import activityLog from "../app/activityLog/vuex/index.js";
import contactUs from "../app/contactUs/vuex/index.js";
import emailTemplates from "../app/emailTemplates/vuex/index.js";
import localization from "../app/localization/vuex/index.js";
import masterData from "../app/masterData/vuex/index.js";
import settings from "../app/settings/vuex/index.js";

export default createStore({
    //state,
    state: {
        alerts: {
            default: false,
            success: false,
            warning: false,
            info: false,
            message: null
        },
        dkToastConfig: {
            success: {
                duration: 5000,
                positionX: 'center',
                positionY: 'top',
                styles: {
                    color: '#155724',
                    backgroundColor: '#d4edda',
                    borderColor: '#c3e6cb',
                    textAlign: 'left'
                }
            },
            error: {
                duration: 5000,
                positionX: 'center',
                positionY: 'top',
                styles: {
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb',
                    textAlign: 'left'
                }
            },
            warning: {
                duration: 5000,
                positionX: 'center',
                positionY: 'top',
                styles: {
                    color: '#856404',
                    backgroundColor: '#fff3cd',
                    borderColor: '#ffeeba',
                    textAlign: 'left'
                }
            }
        }
    },
    getters,
    mutations,
    actions,
    modules: {
        auth: auth,
        adminProfile: adminProfile,
        adminDashboard: adminDashboard,
        roleAndPermission: roleAndPermission,
        orders: orders,
        myTasks: myTasks,
        exhibitions: exhibitions,
        users: users,
        artLibrary: artLibrary,
        //photoLibrary: photoLibrary,
        //frameLibrary: frameLibrary,
        //designLibrary: designLibrary,
        adminCrowdFunding: adminCrowdFunding,
        financeAndAudit: financeAndAudit,
        promotions: promotions,
        referrals: referrals,
        feedbacks: feedbacks,
        errorsLog: errorsLog,
        activityLog: activityLog,
        contactUs: contactUs,
        emailTemplates: emailTemplates,
        localization: localization,
        masterData: masterData,
        settings: settings,
    },
});