export const getErrorLogList = ({ commit, dispatch },query='') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-error-logs'+query).then((response) => {
        commit('setErrorLogList', response.data);
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const updateErrorLogStatus = ({ commit, dispatch },payload) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/update-error-log',payload.payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const deleteErrorLog = ({ commit, dispatch },payload) => {
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + 'api/delete-error-log/'+payload.payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}