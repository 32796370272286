export const showEditViewLocalization = (state, flag) => {
	state.showEditViewLocalization = flag;
}
export const setLanguageList = (state, data) => {
    state.languageList.data = data.data.data;
    state.languageList.links = data.data.links;
    state.languageList.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}

