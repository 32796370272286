export const getProductsListData = ({ commit, dispatch }, query='') => {
    // if(query == ''){
    //     query = '?perPage=1000';
    // }
    let url = process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-product/list'+ query;
    return window.axios.get(url).then((response) => {
        commit('SET_PRODUCTS_LIST_DATA', response.data)
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
};
export const deleteProduct = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-product',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        // commit('SET_ALERT', 'warning', { root: true })
        // commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
};
export const getProductDetails = ({ commit, dispatch }, { payload, context }) => {
    let id = payload.id;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/product/details/' + id).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}
export const saveProductDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/product/create/' + payload.id, payload).then((response) => {
        if(response.data.success){
            commit('SET_ALERT', 'success', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            return response;
        } else {
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            return response;
        }
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

export const updateProductImagesFiles = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/product/update-images-files', payload,{
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
        },
    }).then((response) => {
        //commit('SET_ALERT', 'success', { root: true })
        //commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}
export const deleteProductImageFile = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/product/remove-images-files',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}
