export const getShowViewErrors = (state) => {
	return state.showViewErrors
}
export const getErrorLogList = (state) => {
    return state.errorLogList.data
}

export const getErrorLogListLinks = (state) => {
    return state.errorLogList.links
}

export const getErrorLogListMeta = (state) => {
    return state.errorLogList.meta
}