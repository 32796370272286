import { createApp } from 'vue'
import './index.css';
import gliderHelper from './plugins/js/glider-helper.js'
window.glider = gliderHelper

import axios from 'axios'
import VueAxios from 'vue-axios'
import localforage from 'localforage'
import router from './router'
import store from './store/store.js';

import './plugins/js/glider.min.js'
import './plugins/css/glider.min.css'
import CKEditor from '@ckeditor/ckeditor5-vue';
import DKToast from 'vue-dk-toast'
/*
when we install CKEditor please use this commands
npm i @ckeditor/ckeditor5-vue
npm i @ckeditor/ckeditor5-build-classic
*/

import VueApexCharts from "vue3-apexcharts";
/*
when we install ApexChart please use this commands
npm install --save apexcharts
npm install --save vue3-apexcharts
*/

/* vue multiselect command and URL and examples
installation command-> npm install @vueform/multiselect
url-> https://github.com/vueform/multiselect#using-with-vue-3
url-> https://jsfiddle.net/t421d7cg/
*/

/* with npm
 npm install primevue primeicons
 https://primevue.org/calendar/
*/
import PrimeVue from 'primevue/config';
//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";
//core
import "primevue/resources/primevue.min.css";
//icons
import "primeicons/primeicons.css";

import App from './App.vue'
import i18n from './i18n'

window.axios = axios.create({
    baseURL: process.env.VUE_APP_SSO_API_URL || '',
    headers: {
        Accept: 'application/json'
    }
})

localforage.config({
    driver: localforage.LOCALSTORAGE,
    storeName: 'smartartadmin'
})

store.dispatch('auth/setToken').then(() => {
    store.dispatch('auth/fetchUser').then(() => {
    }).catch(() => {
        store.dispatch('auth/clearAuth')
        router.replace({ name: 'Sign-In'})
    })
}).catch(() => {
    store.dispatch('auth/clearAuth')
})

const app = createApp(App).use(i18n).use(store).use(router).use(VueApexCharts).use(CKEditor).use(PrimeVue)

app.use(VueAxios, axios);
app.use(DKToast);
app.mount('#app')


window.alertDuration = process.env.VUE_APP_ALERT_DURATION || 5000

//  common functions added by Mukesh Rane to use on all the pages
app.mixin({
    methods: {
        NumbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        gFormatedAmount(value) {
			return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		gFormatedNumber (num) {
			return parseFloat(num).toFixed(2);
		},
		gFormatedAmountNumber(num){
			return this.gFormatedAmount(this.gFormatedNumber(num));
		},
        roundToDecimal(num) {
            return Math.round(num);
        },
        gFormatedAmtNumWithoutDecimal(num){
            let newNumber = Math.floor(num);
            newNumber = newNumber.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");              
			return newNumber;
		},
        gFormatedDate(date){
            let today = new Date(date);
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();
            return today = dd + '/' + mm + '/' + yyyy;
            //alert(today);
        },
        gFormatDateYMD(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        gIndicator(status){
            if(status == 'start'){
                document.getElementById("template_loader_indicator").style.display="block";
            }
            if(status == 'stop'){
                document.getElementById("template_loader_indicator").style.display="none";
            }
        },

        getRecordListingSummary(metaData) {
            if (metaData != null) {
                let from = (metaData.from != null) ? metaData.from : 0
                let to = (metaData.to != null) ? metaData.to : 0
                if (from == 0 && to == 0) {
                    return this.$t('no-data-found')
                }
                return this.$t('showing')+ ' '+ from + ' '+this.$t('to')+' ' + to +' '+  this.$t('of') + ' '+ metaData.total + ' ' + this.$t('entries')
            }
           return this.$t('loading-data')
        },


        haspermission(permission){
            let hasPermission = false;
            const permissions = [];
            const getData = JSON.parse(localStorage.getItem('sso_data'));
            const getRoles = getData && getData.hasOwnProperty('roles') ? getData.roles : false;

            if(getRoles && getRoles.length){
                for(var i=0; i<getRoles.length; i++){
                    const getPermission = getRoles[i].hasOwnProperty('permissions') ? getRoles[i].permissions : false;
                    if(getPermission && getPermission.length){
                        for(var k=0; k<getPermission.length; k++){
                            if(!permissions.includes(getPermission[k].name)){
                                permissions.push(getPermission[k].name);
                            }
                        }
                    }
                }
            }
            if (permissions.length) {
                hasPermission = permissions.includes(permission) ? true : false;
            }
            return hasPermission;
        },
        async bellNotification(frmData){
            try{
                const result = await this.$store.dispatch("sendBellNotification",{payload: frmData});
            }catch(e){
                console.log('error in bell notification',e);
            }
        },
        allowLoggedInUserToAccess(){
            let userAuthToken = localStorage.getItem("localforage/smartartadmin/authtoken");
            if(userAuthToken !== null && userAuthToken != ""){
                return true;
            }
            window.location = process.env.VUE_APP_LOCAL_API;
        },
        titleCase(str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                // You do not need to check if i is larger than splitStr length, as your for does that for you
                // Assign it back to the array
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
            }
            // Directly return the joined string
            return splitStr.join(' '); 
        }
    }
})
