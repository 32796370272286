export const showViewErrors = (state, flag) => {
	state.showViewErrors = flag
}
export const setErrorLogList = (state, data) => {
    state.errorLogList.data = data.data.data;
    state.errorLogList.links = data.data.links;
    state.errorLogList.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}