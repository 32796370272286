export const fetchContactList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/contact-us' + query).then((response) => {
        commit('SET_CONTACTUS_LIST', response.data)
        return true
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const getContactDetails = ({ commit, dispatch }, {payload}) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/contact-us/' + payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}
export const reply = ({ commit, dispatch }, {payload}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/contact-us-reply', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false
    })
}