export default {
  "jp": {
    "smart-art-welcomes-you-to-world-of-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art はアートの世界へようこそアーティストとアートコレクターにとって最高のプラットフォームの 1 つ"])},
    "sekai-ichi-smart-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セカイイチ スマートアート\n"])},
    "my-tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイタスク"])},
    "admin-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者名"])},
    "exhibit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示する"])},
    "art-photo-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート-写真展"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール"])},
    "roles-and-permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割と権限"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー"])},
    "art-library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートライブラリー"])},
    "photo-library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォトライブラリ"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
    "new-registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規登録"])},
    "products-uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードされた製品"])},
    "frame-vendors ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームベンダー"])},
    "revenue-earned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得した収益"])},
    "payment-released-by-sekai-ichi ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いのリリース者 SEKAI-ICHI"])},
    "payment-pending ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い保留中"])},
    "custom-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムアート"])},
    "art-completer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートコンプリーター"])},
    "feedback-reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバック/レビュー"])},
    "errors-log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーログ"])},
    "activiy-log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動ログ"])},
    "email-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールテンプレート"])},
    "master-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マスタデータ"])},
    "localization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ローカリゼーション"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
    "sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインアウト"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
    "name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "role ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割"])},
    "super-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スーパー管理者"])},
    "login-email ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインメール"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード変更"])},
    "personal-details ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報"])},
    "address-permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所*(永久)"])},
    "address-line-one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス行1"])},
    "address-line-two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス行2"])},
    "address-line-three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス行3"])},
    "director": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["院長"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理"])},
    "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファクシミリ"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウェブサイト"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィールの編集"])},
    "add-photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真を追加"])},
    "editable ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集可能"])},
    "enter-old-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["古いパスワードを入力"])},
    "enter-new-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを入力してください"])},
    "re-enter-new-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを再入力してください"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショー"])},
    "entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エントリー"])},
    "art-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート名"])},
    "date-range ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付範囲"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
    "payment-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払請求"])},
    "return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品依頼"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け入れられた"])},
    "paid-to-buyer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイヤーに支払われる"])},
    "paid-to-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売人に支払われる"])},
    "cancel-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文をキャンセルする"])},
    "product-details ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品詳細"])},
    "with-case ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ケース付き"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け入れる"])},
    "attached-id-proof ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添付のId証明"])},
    "rate-this-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この製品を評価する"])},
    "payment-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いのご依頼"])},
    "confirm-order ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文の確認"])},
    "metallic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メタリック"])},
    "orders-payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文のお支払い状況"])},
    "payment-done-from ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から行われた支払い :"])},
    "first-name-last-name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名姓"])},
    "payment-date-and-time ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い日時"])},
    "reference-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参照番号"])},
    "track-order ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トラック注文"])},
    "delivery-status ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送状況"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中"])},
    "courrier-company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クーリエ会社名"])},
    "attached-photograph-of-delivered-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お届けした商品の写真を添付してください。"])},
    "delivered ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["納品済み"])},
    "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["印刷"])},
    "once-order-placed-return-product-within": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["順序が置かれれば、プロダクトを中戻すことができます"])},
    "seven-days-after-delivery-owned-by-buyer\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配達後 七 日間、下記の費用は購入者が負担します。"])},
    "reason-for-returning-the-item ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品を返品する理由"])},
    "want-the-correct-art-back ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正しいアートを取り戻したい"])},
    "want-the-money-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻ってお金をしたいです"])},
    "sekai-ichi-rental-deposit-payment-to-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI賃貸保証金の支払い詳細を購入者に"])},
    "sekai-ichi-payment-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI 支払いの詳細"])},
    "i-received-different-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私は別の項目を受け取りました"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
    "artist-returned-item ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティスト返品商品"])},
    "sekai-ichi-payment-details-to-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI 販売者への支払い詳細"])},
    "purchase-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入依頼"])},
    "previous-rental-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前のレンタル日"])},
    "future-rental-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将来のレンタル日"])},
    "orders-renew-payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文/の更新された支払いステータス"])},
    "renewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リニューアルしました"])},
    "artist-not-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティストは受け付けていません"])},
    "product-budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品予算"])},
    "custom-order-placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムオーダー"])},
    "auction-applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オークション適用"])},
    "artist-accepted ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティストが受け入れられました"])},
    "order-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中の注文"])},
    "order-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文完了"])},
    "paid-to-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティストに支払われる"])},
    "reference-images ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考画像"])},
    "art-orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートオリエンテーション"])},
    "applied-artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応用芸術家"])},
    "bid-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入札価格"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引"])},
    "completed-art-images ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成したアート画像"])},
    "rental-deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賃貸保証金"])},
    "rent-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賃貸リクエスト"])},
    "under-lease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リースの下で"])},
    "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返された"])},
    "fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金"])},
    "accept-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会を受け入れる展覧会を受け入れる"])},
    "reject-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リジェクト展"])},
    "presenting ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレゼンテーショ"])},
    "enter-as-exhibitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出展者としてエントリー"])},
    "enter-as-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出席者として入力します"])},
    "crowd-funding-is-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングが要求されます"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全て"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご要望-ご要望"])},
    "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジット"])},
    "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["費用"])},
    "audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["監査"])},
    "financial-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計年度"])},
    "flexible-duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["柔軟な期間"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有料"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["演技はじめ！"])},
    "payment-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いタイプ"])},
    "reference ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考文献"])},
    "credit-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジット金額"])},
    "payment-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払期日"])},
    "feedback-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["によるフィードバック"])},
    "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信"])},
    "buy-corporate-membership-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の仕事-お問い合わせリスト"])},
    "add-new-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいロールの追加"])},
    "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロール名"])},
    "created-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日"])},
    "edited-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集された日付"])},
    "copy-existing-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既存のロールのコピー"])},
    "functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関数"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パーミッション"])},
    "frame-library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームライブラリ"])},
    "design-library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインライブラリ"])},
    "finance-and-audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財務-監査"])},
    "promotions-messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロモーション（メッセージング）"])},
    "referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご紹介"])},
    "error-logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーログ"])},
    "activity-logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動ログ"])},
    "contact-us ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])},
    "custom-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムフィールド"])},
    "view-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビューの役割"])},
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承認"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リスト"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビュー"])},
    "edit-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロールの編集"])},
    "delete-role ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割の削除"])},
    "want-to-delete-the-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは本当に、あなたは役割を削除したいですか"])},
    "role-in-use-can-not-delete-this-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロールが使用中である場合、このロールを削除することはできません。"])},
    "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの追加"])},
    "user-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーリスト"])},
    "internal-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部ユーザー"])},
    "subscribed-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読済みユーザー"])},
    "user-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー名"])},
    "company-name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社名"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モバイル"])},
    "ui-applicable-to-users-and-buyers-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このUiは、内部ユーザーと購入者にのみ適用されます"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理者"])},
    "company-details ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の詳細"])},
    "ui-is-applicable-to-company-and-personal-buyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このUiは、内部ユーザーと購入者（会社と個人の購入者）にのみ適用されますが、\n購入者は購読済みユーザーに表示されます）"])},
    "ui-is-applicable-to-subscribed-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このUiは、購入者を除く購読ユーザー（アーティスト、写真家、デザイナー、建築家、展示会主催者）に適用されます"])},
    "bank-account-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行口座名"])},
    "bank-branch ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行支店"])},
    "bank-passbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行通帳"])},
    "file-size-must-be-less-than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルサイズは以下でなければなりません"])},
    "mb-only ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MBのみ"])},
    "paypal-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペイパルiD"])},
    "my-bank-details ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の銀行の詳細"])},
    "total-revenues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総収入"])},
    "download-sales-record ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダウンロード販売実績"])},
    "disk-quota-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ディスククォータの状態"])},
    "disk-quota-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ディスククォータ：無料"])},
    "used-space ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中古スペース"])},
    "free-space  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フリースペース"])},
    "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中古"])},
    "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残り"])},
    "disk-quota-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ディスククォータ：有料"])},
    "unlimited-disk-space ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無制限のディスク容量"])},
    "days-left-expires-on ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残りの日。 上で有効期限が切れます"])},
    "optional-services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプションサービス"])},
    "purchased-services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入したサービス"])},
    "disk-space-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ディスク容量料金"])},
    "total-amount-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払総額"])},
    "this-ui-is-applicable-for-external-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この UI は購入者を除く外部ユーザー (アーティスト、写真家、デザイナーなど) に適用されます。"])},
    "view-art\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビューアート"])},
    "purchase-disk-quota-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐディスククォータを購入"])},
    "art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート"])},
    "renew ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
    "ready-for ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["のための準備ができて"])},
    "sale-cost-including-frame\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売コスト（フレームを含む）\n"])},
    "service-charge ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス料"])},
    "of-sale-cost-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売コスト（購入者）の"])},
    "you-receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたが受け取る"])},
    "publishing-amount-to-sale-auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売-オークションへの掲載金額"])},
    "rental-cost-per-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レンタル費用（月額）"])},
    "rental-cost-including-frame\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レンタル費用（フレーム含む）"])},
    "service-charge-for-buyer\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス料\n（購入者）"])},
    "refund-policy ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金ポリシー"])},
    "deposit-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金費用"])},
    "upload-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルのアップロード"])},
    "service-charge-will-be-charged-if-delivery-charges-are-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス料は、販売/レンタル/カスタムオーダー費用に課金されます。 配達および保険料が多くよりあれば"])},
    "for-domestic-and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内および"])},
    "promotion-offer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロモーションオファー"])},
    "give-discount-to-promote-profile ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのプロフィールを促進するためにあなたのバイヤーに割引を与えることができます"])},
    "non-mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（必須ではありません）"])},
    "discount-percentage ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引率"])},
    "discount-you-are-providing-on-sale-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたが提供している割引は、あなたの販売コスト/家賃コストにあります"])},
    "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始日"])},
    "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日"])},
    "delete-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートを削除する"])},
    "want-to-delete-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは本当に、あなたはこの芸術を削除したいですか"])},
    "explain-reason-deleting-the-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートを削除する理由を説明する"])},
    "exhibition-is-postponed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何らかの理由で展覧会は延期されます"])},
    "event-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント写真"])},
    "fashion-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファッション写真"])},
    "nature ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自然"])},
    "frame-vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームベンダー"])},
    "delete-photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームの削除"])},
    "want-to-delete-photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは本当に、あなたは写真を削除したいですか"])},
    "explain-the-reason-for-deleting-photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真を削除する理由を説明する"])},
    "view-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビューフレーム"])},
    "wood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木"])},
    "cedar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杉"])},
    "spruce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スプルース"])},
    "pine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["松"])},
    "photo-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真の説明"])},
    "frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレーム"])},
    "delete-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームの削除"])},
    "want-to-delete-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確かに、フレームを削除しますか"])},
    "explain-reason-for-deleting-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームを削除する理由を説明する"])},
    "exhibitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示会"])},
    "add-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出品を追加しました。"])},
    "organised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["組織化された"])},
    "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今後の予定"])},
    "edit-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集展"])},
    "delete-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会を削除します。"])},
    "last-date-to-register-dd-mm-yyyy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後に登録する日付:dd-mm-yyyy"])},
    "enter-as-virtual-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仮想出席者として入力します"])},
    "can-edit-delete-exhibition-as-organiser-before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは前に主催者としてあなたの展覧会を編集/削除することができます"])},
    "days-of-the-date-of-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会の日の10日"])},
    "organizer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主催者"])},
    "exhibition-name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会名"])},
    "exhibition-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出展料"])},
    "service-fee-charged-on-total-revenue-generated-by-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス料は、展示会によって生じた総収益に基づいて SEKAI-ICHI によって請求されます"])},
    "exhibitors-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出展者の費用"])},
    "attendees-virtual-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加者バーチャルコスト"])},
    "attendees-physical-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加者の物理的なコスト"])},
    "select-start-date-time-and-timezone-for-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会の開始日、時間、タイムゾーンを選択します"])},
    "select-end-date-time-and-timezone-for-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会の終了日、時間、タイムゾーンを選択します"])},
    "maximum-exhibitors-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大出展者数"])},
    "no-restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制限なし"])},
    "maximum-subscribers-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["許可される最大加入者数"])},
    "virtual-exhibition-preview-attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バーチャル展示会プレビュー添付ファイル"])},
    "virtual-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バーチャル展示会"])},
    "physical-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物理的な展覧会"])},
    "you-can-edit-delete-exhibition-as-organiser-before ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは前に主催者としてあなたの展覧会を編集/削除することができます"])},
    "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否されました"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["衰退"])},
    "attendees-subscribed ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加者登録"])},
    "above": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上"])},
    "buyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイヤーズ"])},
    "nature-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自然展"])},
    "physical-exhibition-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物理的な展覧会の住所"])},
    "the-link-is-sent-to-exhibitors-and-attendees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注：リンクは、電子メールで出展者と出席者に送信されます"])},
    "visible-in-the-exhibition-on-day-of-the-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示会当日、展示会当日に展示会で見ることができます"])},
    "cancel-subscription ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読をキャンセルする"])},
    "want-to-cancel-subscription-for-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは本当に、あなたは展覧会のためのあなたの購読をキャンセルしたいですか"])},
    "reason-of-cancelling-subscription-for-the-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会の購読をキャンセルする理由は何ですか？"])},
    "due-to-some-reasons-exhibition-postponed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["何らかの理由で展覧会は延期されます"])},
    "cancel-exhibition-subscription-request-sent-to-super-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会のためのあなたの購読をキャンセルするためのあなたの要求は、スーパー管理者に送信されます。\nリクエストを受け入れた後に通知されます"])},
    "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分かった。"])},
    "pending-requests ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページ数"])},
    "accepted-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け入れられた要求された"])},
    "artist-art-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティスト/アートタイプ"])},
    "accepted-requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け入れられた要求"])},
    "reject-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエストを拒否"])},
    "accept-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエストを受け入れる"])},
    "purchase-pending ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入保留中"])},
    "the-crowd-funding-is-accepted ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングは受け付けています"])},
    "sale ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売"])},
    "art-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート-イン-プログレス"])},
    "art-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート完成"])},
    "delivery-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送先住所"])},
    "in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
    "artist-review-on-buyers-comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイヤーのコメントにアーティストのレビューコメント"])},
    "add-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットの追加"])},
    "total-credit ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総クレジット"])},
    "delete-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットの削除"])},
    "want-to-delete-the-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは本当に、あなたはクレジットを削除したいですか？"])},
    "view-credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットの削除"])},
    "expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["費用"])},
    "view-expence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["経費の表示"])},
    "add-expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["費用の追加"])},
    "expense-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["費用額"])},
    "expense-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["経費タイプ"])},
    "paid-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["に支払われた"])},
    "payment-due-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払期日"])},
    "paid-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払日"])},
    "payment-status ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い状況"])},
    "waiting-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待機中の支払い"])},
    "profit-loss ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["損益 "])},
    "debit ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["借方"])},
    "bank-transaction ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行取引"])},
    "add-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引の追加"])},
    "discrepancy-between-system-audit-and-bank-audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「システム間には不一致があります生成された監査と銀行残高監査。"])},
    "bank-balance ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行残高"])},
    "bank-transaction-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行取引日"])},
    "want-to-delete-bank-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは本当に、あなたは削除したいですか 銀行取引？"])},
    "messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージング"])},
    "mail-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールタイトル"])},
    "mail-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールテンプレート"])},
    "mail-contents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール内容"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添付ファイル"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["署名"])},
    "invite-referrals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介を招待する"])},
    "referral-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介者名"])},
    "referral-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介メールId"])},
    "referred-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["によって参照される"])},
    "referred-dates\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参照された日付"])},
    "subscribed-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読日"])},
    "referral-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介リンク"])},
    "generates-an-email-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単一の入力フィールドにコンマで区切られた複数のメールアドレスを入力して、電子メールリストを生成します"])},
    "invite-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待メッセージ"])},
    "send-invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待を送る"])},
    "delete-refferal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介の削除"])},
    "want-to-delete-the-referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介を削除してもよろしいですか?"])},
    "error-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーコード"])},
    "error-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラータイトル"])},
    "module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モジュール"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
    "view-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビューエラー"])},
    "resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解決済み"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージ"])},
    "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンテキスト"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
    "export ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸出"])},
    "violation-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["違反報告"])},
    "customer-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様名"])},
    "module-membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モジュール会員"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター"])},
    "tabular-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表形式ビュー"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリー"])},
    "search-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリを検索"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様"])},
    "balanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バランスのとれた"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間"])},
    "from-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付から"])},
    "to-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在までに"])},
    "send-reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信を送る"])},
    "add-email-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールテンプレートの追加"])},
    "date-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新日"])},
    "created-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["によって作成された"])},
    "used-in-function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関数で使用される"])},
    "html-file ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTMLファイル"])},
    "page-name-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページ名/URL"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["値"])},
    "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["で作成された"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インポート"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メニュー"])},
    "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定期購読"])},
    "about-us ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私たちについて"])},
    "edit-translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳の編集"])},
    "view-translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["翻訳を見る"])},
    "subscription-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読料"])},
    "agreements\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約書"])},
    "slack-function ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スラック機能"])},
    "slack-integration ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackとの連携"])},
    "auto-email-dispatch\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動メール派遣"])},
    "base-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベースURL"])},
    "site-access-reminder-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイトアクセスリマインダー時間"])},
    "referral-rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介報酬"])},
    "japan-consumption-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本消費税"])},
    "japan-tax-registration-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本の税務登録番号"])},
    "image-upload-max-size-free-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像アップロード最大サイズ（無料ユーザー）"])},
    "image-upload-max-size-paid-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像アップロード最大サイズ（有料ユーザー）"])},
    "applicable-currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用通貨"])},
    "feedback-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバック/レビュー"])},
    "unpublish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未公開"])},
    "weeks ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週"])},
    "free-disk-quota": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ディスクの空き容量"])},
    "own-file-server-disk-quota-cost-per-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独自のファイルサーバーディスククォータコスト/年"])},
    "sekai-ichi-disk-quota-rental-cost-per-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セカイイチディスククォータ小売コスト/年"])},
    "slack-notification-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack通知手数料"])},
    "advance-deposit-cost-for-rental-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レンタル芸術のための前金の費用"])},
    "service-charge-on-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文時のサービス料"])},
    "service-charge-on-exhibition-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示会利用者のサービス料"])},
    "service-charge-for-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングのサービス料"])},
    "anniversary-event-discount-in-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周年記念イベント割引ご注文で"])},
    "limit-to-delivery-insurance-charge-for-domestic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送制限+国内の保険料"])},
    "limit-to-delivery-insurance-charge-for-international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国際のための配達+保険料への限界"])},
    "add-new-payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいお支払い方法の追加"])},
    "start-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始月"])},
    "end-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月末"])},
    "you-can-choose-start-and-end-of-financial-year ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会計年度の開始と終了を選択できます。"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効になっている"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効にする"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "stripe-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストライプキー"])},
    "stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストライプ"])},
    "sekai-engineering-it-solutions-private-limited  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI Engineering and IT Solutions Pvt Ltd."])},
    "make-payment-to-paypal-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal iDへの支払いを行う"])},
    "stripe-secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストライプシークレット"])},
    "local-file-server-hosting-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ローカルファイルサーバー（ホスティングサーバー）"])},
    "aws-s3-bucket-file-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS S3バケットファイルサーバー"])},
    "connection-tested-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続が正常にテストされました。"])},
    "check-connection-details-and-try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S3バケットに接続できませんでした。接続の詳細を確認してから、もう一度お試しください。"])},
    "international-delivery ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国際配送"])},
    "add-new-agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい契約書の追加"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル"])},
    "contents ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容"])},
    "delete-agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約書の削除"])},
    "want-to-delete-contract-agreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは本当に、あなたは契約契約を削除したいですか"])},
    "agreement-is-in-use-it-can-not-be-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約が使用中である、それは削除することはできません。"])},
    "add-new-slack-function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいSlack機能を追加"])},
    "function-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関数名"])},
    "applicable-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["に適当"])},
    "required-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須項目"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
    "tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テナント"])},
    "view-slack-function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack機能を見る"])},
    "mobile-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モバイル/お問い合わせ"])},
    "add-new-slack-webhook-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいSlack Webhook URLを追加する"])},
    "slack-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackチャンネル"])},
    "add-new-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいモジュールの追加"])},
    "landing-page ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランディングページ"])},
    "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様"])},
    "users-geography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー/地理"])},
    "mail-mailer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールメーラー"])},
    "mail-host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールホスト"])},
    "mail-port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールポート"])},
    "mail-username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールユーザー名"])},
    "mail-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールパスワード"])},
    "mail-encryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール暗号化"])},
    "file-server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルサーバー"])},
    "do-not-have-account ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを持っていませんか？"])},
    "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
    "smart-art-welcomes-to-world-of-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアート アートの世界へようこそ"])},
    "Sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインアップ"])},
    "agreement-for-international-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国際配送に関する契約"])},
    "return-policy ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品規則"])},
    "forgot-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れですか？"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["近い"])},
    "have-an-account ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントを持っています ？"])},
    "artist ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\nアーティスト"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い手"])},
    "photographer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\n写真家"])},
    "designer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザイナー"])},
    "architect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建築家"])},
    "new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいユーザー"])},
    "member-of-jiipa-services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JIPAサービス会員"])},
    "member-of-smart-recruit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートリクルート会員"])},
    "member-of-sekai-ichi-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["世界一プロダクツ会員"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファーストネーム"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["苗字"])},
    "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを認証する"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認する"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録する"])},
    "industry ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業界"])},
    "industry-subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業界のサブカテゴリ"])},
    "designation ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部門"])},
    "landline-telephone ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定電話"])},
    "mobile-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号"])},
    "fax-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファクス番号"])},
    "company-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社のウェブサイト"])},
    "pin-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピンコード"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["州"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国"])},
    "free-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料ユーザー"])},
    "business-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネスユーザー"])},
    "you-will-be-provided-with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供されるのは"])},
    "free-disk-space-to-upload-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート画像をアップロードするための無料のディスク容量。"])},
    "slack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スラック"])},
    "automatic-mobile-notifications-per-year\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間の自動モバイル通知:"])},
    "select-below-option-for-additional-disk-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加のディスク容量を使用するには、以下のオプションを選択してください"])},
    "file-server-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルサーバーの詳細"])},
    "rent-from-sekai-ichi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セカイイチから借りる"])},
    "own-file-server ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独自のファイルサーバー"])},
    "aws-connection-cost-per-year ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間あたりの AWS 接続コスト:"])},
    "server-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーURL*"])},
    "target-bucket-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ターゲットバケット名*"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域*"])},
    "secret-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秘密鍵*"])},
    "test-connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テスト接続"])},
    "slack-notification-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["緩み通知料"])},
    "aws-connection-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWS 接続料金"])},
    "total-amount-to-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払総額"])},
    "Japan-consumption-tax-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本の消費税額"])},
    "total-amount-excluding-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税抜合計金額"])},
    "way-to-smart-life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートライフへの道！"])},
    "we-provide-best-solutions-for- businesses-and-people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私達はビジネスと人々に最もよい科学技術の解決を提供します。"])},
    "know-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細を知る"])},
    "payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払方法"])},
    "amount-to-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払う金額"])},
    "credit-debit-card ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジット/デビットカード"])},
    "name-on-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード上の名前"])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード番号"])},
    "expiry-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
    "blank-fields ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空白のフィールド"])},
    "bank-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行振込"])},
    "please-make-payment-within ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以内にお支払いください"])},
    "to-below-bank-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下の銀行口座へ"])},
    "account-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント名"])},
    "bank-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行名"])},
    "branch-name ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支店名"])},
    "account-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["口座の種類"])},
    "account-number ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["口座番号"])},
    "swift-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スウィフトコード"])},
    "ifsc-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IFSCコード"])},
    "paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペイパル"])},
    "send-payment-to-paypal-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal ID に支払いを送信します"])},
    "final-payment-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終的な支払いの詳細"])},
    "being-processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["処理中"])},
    "payment-time ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い時間"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "pay-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今払う"])},
    "company-buyer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企業バイヤー"])},
    "personal-buyer ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人購入者"])},
    "exhibition-organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示会主催者"])},
    "unlimited-disk-space-per-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間無制限のディスク容量"])},
    "sign-into-your-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントにサインインする"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス"])},
    "remember-me ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私を覚えてますか"])},
    "welcome-to-the-world-of-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートの世界へようこそ"])},
    "smart-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアート"])},
    "1st-choice-of-every-artist-and-art-collector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのアーティストとアートコレクターの第1の選択"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
    "gallery ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギャラリー"])},
    "crowd-funding ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディング"])},
    "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォーラム"])},
    "find-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートを探す"])},
    "find-Photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真を探す"])},
    "find-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームの検索"])},
    "find-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインを探す"])},
    "find-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示会を探す"])},
    "art-name-type ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート名、種類"])},
    "artist-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティスト名"])},
    "art-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートカテゴリー"])},
    "register-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ登録"])},
    "buy ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買う"])},
    "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家賃"])},
    "custom-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムオーダー"])},
    "about-smart-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアートについて"])},
    "art-on-every-step ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一歩ごとにアート"])},
    "bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寝室"])},
    "toilets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トイレ"])},
    "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受付"])},
    "meeting-rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会議室"])},
    "buy-arts-for-your-bedroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寝室用にアートを購入"])},
    "buy-arts-for-your-office ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフィス用にアートを購入する"])},
    "rent-items-keep-changing-views ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイテムをレンタルして見方を変え続ける"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボード"])},
    "detailed-attractive-smart-dashboard\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細な魅力的なSMARTダッシュボード"])},
    "sell-art-worldwide-at-good-price-with-SMART ART": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ARTを使用すると、あなたのアートを手頃な価格で世界中に販売できます"])},
    "smart-art-is-best-platform-to-sell-art-globally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のデジタル世界では、スマート アートはアーティストが自分のアートを世界中に販売するための最良のプラットフォームです"])},
    "smart-art-customer-base-is-in-more-art-loving-countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART の顧客ベースは、日本、ベトナム、インド、アメリカといったアートを愛する 5 か国以上にあります。"])},
    "increase-in-independent-artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インディペンデントアーティストの増加"])},
    "smart-art-is-having-affordable-service-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art は、サービス料金が非常に安いため、他のアート販売 Web サイトと比較して非常に手頃な価格です。"])},
    "popularity-of-pop-up galleries-is-global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポップアップギャラリーの人気は世界的です"])},
    "independent-artists-can-reach-broader-audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独立系アーティストはより幅広い聴衆にリーチできる"])},
    "smart-art-gives-fast-customer-support ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art はスマートで迅速なカスタマー サポートを提供します"])},
    "crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディング"])},
    "orders ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文"])},
    "funds-received-by-buyers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い手が受け取った資金"])},
    "funds-paid-to-owners ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有者に支払われる資金"])},
    "pending-payments ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中の支払い"])},
    "physical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物理的な"])},
    "virtual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バーチャル"])},
    "physical-and-virtual-both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物理と仮想の両方"])},
    "feedback ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバック"])},
    "smart-art-sellers-benefits ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART 販売者のメリット"])},
    "with-smart-art-you-can-create-your- art-exhibition-virtually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代わりに、展覧会SMART ARTのためにある場所から別の場所にあなたのアート作品を移動するのは、あなたのアートを作成するためのサービスを提供します\n事実上展覧会または他の展覧会のあなたの芸術を表わして下さい"])},
    "smart-art-saves-time-searching-buyer-for-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART あなたのアートの最適な購入者を探す時間を節約します"])},
    "registering-to-smart-art-you-will-be-more-popular-globally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART に登録すると、アート分野で世界的に人気が高まります"])},
    "smart-art-get-orders-worldwide-customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART の助けを借りて、あなたの仕事を高く評価する世界中の顧客からカスタムオーダーを得ることができます"])},
    "sale-auction ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売・オークション"])},
    "buy-rent ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入/レンタル"])},
    "exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会"])},
    "smart-art-buyers-benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART購入者のメリット"])},
    "smart-art-allows-buy-favourate-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART あなたの場所から世界中のお気に入りのアートを手頃な価格で購入できます"])},
    "smart-art-best-platform-to-buy-rent-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のデジタル世界では、スマートアートはアート愛好家にとって、制限や国の境界なしにアートを購入/レンタルできる最良のプラットフォームです"])},
    "popularity-of-pop-up-galleries-is-global ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\n私たちのポップアップ ギャラリーの人気は世界的なものなので、世界中のトップ アーティストのアートを Smart Art で購入したりレンタルしたりできます"])},
    "instead-of-going-out-buy-or-rent-art-piece ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート作品を展示したり購入したりレンタルしたりするために外出する代わりに"])},
    "with-SMART ART-you-can-give-custom-orders-to-world-wide-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART の助けを借りて、世界最高のアーティストにカスタムオーダーを行うことができます。"])},
    "you-can-avail-discount-on-special-events ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART を使用すると、特別なイベントでアートを購入する際に 10% 割引を利用できます"])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "buy-now ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ購入"])},
    "rent-now ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐレンタル"])},
    "reviews-and-feedback ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レビューとフィードバック"])},
    "add-your-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィードバックを追加してください"])},
    "view-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて見る"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
    "jai-bhim-house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["横浜市鶴見区栄町通一-四-三十四 ジャイビムハウス〒二百三十-三十八 神奈川県日本。"])},
    "or-write-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または、お問い合わせください"])},
    "contact-number\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先番号"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメント"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提出する"])},
    "sekai-ichi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セカイイチ"])},
    "company-mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["は、今日の世界のさまざまな問題に対する革新的なソリューションを開発することを使命とする会社です。\n私たちは、生活様式を変えるようなものを作ることを奨励します。"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品"])},
    "smart-planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートプランナー"])},
    "smart-recruit\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートリクルート"])},
    "smart-trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートトライアル"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと…"])},
    "art-exhibitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美術展"])},
    "photo-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真展"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社"])},
    "contacts ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先"])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インド"])},
    "india-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["54-ルクミニ ヴィラ、2階 、Dambhare レイアウト 、トリムルティ ナガル環状道路の近く、ナーグプール 440022、インド"])},
    "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本"])},
    "made-by-sekai-ichi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["世界一製"])},
    "rate-us ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私たちを評価してください"])},
    "rental-details ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レンタル詳細"])},
    "rent-for ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["のための賃貸"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数か月"])},
    "minimum-months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低月数"])},
    "maximum-months ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大月数"])},
    "total-rental-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レンタル料金総額"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["わかりました"])},
    "search-the-best-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欲しい最高のアートを検索"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
    "japanese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本語"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイズ"])},
    "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["間"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用する"])},
    "art-gallery\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートギャラリー"])},
    "photo-gallery\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォトギャラリー"])},
    "frame-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームギャラリー"])},
    "showing-arts-for-artist jane-coopers-modify ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「アーティスト ジェーン・クーパース」のアートを表示中"])},
    "all-filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのフィルター"])},
    "art-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートタイプ"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリー"])},
    "cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["から"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["に"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通貨"])},
    "less-than ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未満"])},
    "add-to-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに追加"])},
    "go-to-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートへ行く"])},
    "abstraction\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抽象化"])},
    "conceptual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概念的な"])},
    "pop-culture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポップカルチャー"])},
    "portrait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肖像画"])},
    "religion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宗教"])},
    "canvas-painting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンバス絵画"])},
    "nature\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自然"])},
    "forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["森"])},
    "ocean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["海"])},
    "sports\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポーツ"])},
    "fashion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファッション"])},
    "bollywood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボリウッド"])},
    "hollywood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハリウッド"])},
    "political-etc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["政治など"])},
    "wildlife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["野生動物"])},
    "select-all ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて選択"])},
    "clear-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてクリア"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前の"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次"])},
    "without-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに戻る"])},
    "back-to-my-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに戻る"])},
    "already-registered-user ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでに登録されているユーザー"])},
    "additional-charges-for-delivery-and-insurance-added-as-per-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配達及び保険のための追加料金はあなたが及び提供した住所に従って加えられます\n支払いのための最終的な購入金額ですぐに通知されます。"])},
    "buy-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品を購入する"])},
    "rent-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レンタル商品"])},
    "amazing-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["素晴らしいアート"])},
    "jane-coopers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ジェーン・クーパース"])},
    "request-buy-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエスト 今すぐ購入"])},
    "request-rent-now ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐレンタルをリクエストする"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])},
    "oil-painting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["油絵"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォーマット"])},
    "sub-category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブカテゴリサブカテゴリ"])},
    "physical-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィジカルアート"])},
    "weight ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重さ"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幅"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身長"])},
    "art-frame ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート+フレーム"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
    "washing-guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["洗濯ガイド"])},
    "frame-selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームの選択"])},
    "art-available-for-delivery-in ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートで配達可能"])},
    "roll ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロール"])},
    "stretch ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストレッチ"])},
    "roll-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロールデリバリー"])},
    "ten-percent-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントギフトの購入については、見積価格から 十 パーセントの割引が提供されます"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誕生日"])},
    "photo-id-proof-showing-the-relevant-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当する日付が記載された写真付き身分証明書。"])},
    "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブラウズ"])},
    "shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お届け先の住所"])},
    "all-fields-are-mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(すべてのフィールドは必須です)"])},
    "nagpur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナグプール"])},
    "added-in-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カートに追加されました"])},
    "maharashtra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マハラシュトラ州"])},
    "query-about-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品に関するお問い合わせ"])},
    "product-reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品レビュー"])},
    "request-buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入をリクエストする"])},
    "request-rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家賃をリクエストする"])},
    "see-more-like-this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このようなものをもっと見る"])},
    "product-is-added-to-cart-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品がカートに正常に追加されました"])},
    "your-cart ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのカート"])},
    "place-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["場所の順序"])},
    "you-need-to-enter-delivery-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文を確認するには配送料と保険料を入力する必要があります"])},
    "product-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品原価"])},
    "delivery-charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配達料"])},
    "insurance-charge ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保険料"])},
    "product-rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品の評価"])},
    "download-receipt ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書のダウンロード"])},
    "return-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返却物"])},
    "remove-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品の削除"])},
    "engagement ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["婚約"])},
    "wedding-anniversary ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結婚記念日"])},
    "company-establishment-anniversary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社設立記念日"])},
    "remembrance-day ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戦没者追悼記念日"])},
    "ordered-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["によって順序付け"])},
    "frame-material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレーム素材"])},
    "rental-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レンタル日"])},
    "for-artist-photographer-designer-architect-frame-vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティスト写真家デザイナー建築家フレームベンダー向け"])},
    "return-policy-terms-and-conditions ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品ポリシー/利用規約"])},
    "once-the-order-is-placed-buyer-can-return-at-following-cost\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文後、正当な理由があれば、購入者は納品後 七 日以内であれば購入者が負担する以下の費用で製品を返品できます。"])},
    "sellers-dispatch-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売者の発送料金"])},
    "seller-insurance-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売者保険料"])},
    "return-delivery-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品送料"])},
    "any-damage-charges-of-return-at-source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品時に発生した損害料金。"])},
    "return-due-to-damage-from-seller-charges-paid-by-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["但し、リターンがバイヤーへの配達の前の販売人の側面からの損傷が原因なら、すべての充満は販売人によって耐えられます。"])},
    "terms-and-conditions ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])},
    "i-agree-ten-percent-discount-will-be-provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイヤーがイベントギフトを購入する場合、見積価格から 10% の割引が提供されることに同意します。"])},
    "i-agree-to-return-policy-terms-and-conditions ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品ポリシーとすべての利用規約に同意します"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続く"])},
    "company-buyer-personal-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企業バイヤー 個人バイヤー"])},
    "i-agree-to-return-policy ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品ポリシーに同意します"])},
    "make-payment-within-ten-days-to-bank-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10日以内に以下の銀行口座にお支払いください。"])},
    "success ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功 ！"])},
    "your-payment-done-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いは正常に完了しました。お待ちください。テナンシーページにリダイレクトします"])},
    "send-reset-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセットリンクを送信する"])},
    "reset-password ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再設定する\n"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "todays-offers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日のオファー"])},
    "offer-ends-in ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オファーは次で終了します"])},
    "discount-on-buy-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入商品の割引"])},
    "to-access-all-the-services-in-smart-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアートのすべてのサービスにアクセスするには"])},
    "sale-auction-all-types-of-art-worldwide ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あらゆる種類のアート、写真、デザインを世界中で販売/オークション"])},
    "buy-rent-art-across-world-from-your-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの場所から世界中のアート、写真、デザインを購入/レンタル"])},
    "place-custom-order-and-get-art-at-your-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムオーダーを行って、カスタムアート、写真、またはデザインをあなたの場所で入手してください"])},
    "without-going-feel-real-exhibition-at-virtual-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外出せずに本物の展示を体感できる「スマートアートバーチャル展示会」"])},
    "top-trending-artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トップトレンドアーティスト"])},
    "followed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォローしました"])},
    "view-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィールを見る"])},
    "abstraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抽象化"])},
    "geomatric-art\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幾何学アート"])},
    "find-art\n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートを探す"])},
    "owner-will-let-you-know-soon-total-cost-of-purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オーナーがすぐに購入総額をお知らせします"])},
    "feedback-rank-to-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売者へのフィードバック/ランク"])},
    "submit-product-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品レビューを送信する"])},
    "delivery-on ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配達日"])},
    "purchase-equest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入依頼"])},
    "order-cancelled ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文がキャンセルされました"])},
    "total-cost-disclose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総コストの開示"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い"])},
    "order-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文が確認されました"])},
    "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出荷済み"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クローズド"])},
    "guy-hawkins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガイ・ホーキンス"])},
    "followers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#フォロワー"])},
    "sold-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売された芸術"])},
    "lease-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リース-アーツ"])},
    "pending-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中の芸術"])},
    "top-trending-photographers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トップトレンドの写真家"])},
    "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食べ物！"])},
    "trees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["木"])},
    "animals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動物"])},
    "actors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["俳優"])},
    "actress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女優"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネス"])},
    "buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建物"])},
    "sold-photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売された写真"])},
    "lease-photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リース写真"])},
    "pending-photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中の写真"])},
    "top-trending-frame-vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トップトレンドフレームベンダー"])},
    "top-trending-designers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トップトレンドのデザイナー"])},
    "design-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインギャラリー"])},
    "sold-designs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売されたデザイン"])},
    "lease-designs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リースデザイン"])},
    "pending-designs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中のデザイン"])},
    "hey-friends ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["こんにちはになります！！！"])},
    "are-you-artists ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは芸術家ですか？"])},
    "looking-for-earning-from-your-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのアートで収入を得たいですか?"])},
    "not-getting-clients-for-your-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの芸術のための顧客を得ない!"])},
    "not-getting-proper-promotion-of-your-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの芸術の適切な昇進を得ていない!"])},
    "not-having-proper-identity-in-the-art-world ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートの世界で適切なアイデンティティを持っていない"])},
    "want-the-best-value-of-your-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの芸術の最高の価値をしたいです"])},
    "here-is-solution-of-every-question ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["万歳！\nあらゆる質問の解決策がここにあります"])},
    "best-platform-for-artists-and-art-collectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティストとアートコレクターのための最高のプラットフォームの1つ"])},
    "smart-art-provides-worldwide-customers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ARTは世界的な顧客を提供します"])},
    "smart-dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートダッシュボード"])},
    "smart-art-detailed-attractive-smart-dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアート詳細な魅力的なスマートダッシュボード"])},
    "world-wide-exhibitions ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["世界の展覧会"])},
    "smart-art-provides-special-virtual-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアートはバーチャル展示会と物理的展示会主催サービスの特別なサービスを提供します。自分のアートを展示したり、他の人が自分の展覧会に自分のアートを展示できる場所。あなたは、あなたの場所からバーチャル展示会を主催することができます。世界中のアーティストやアートを愛する顧客にアートを購入またはレンタルしてもらい、展覧会に出品してもらうことができます"])},
    "worldwide-art-buyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワールドワイドアートバイヤーズ"])},
    "smart-art-leading-player-of-art-world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアート\nアートの世界のリーディングプレーヤーであります"])},
    "smart-art-have-worldwide-art-collector-customer-base ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ARTは、世界的なアートコレクターの顧客基盤を持っています"])},
    "more-than-one-million-customers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一万人以上のお客様"])},
    "thousand-plus-arts-solld-every-years ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎年 千 点以上のアートが販売される"])},
    "thousand-new-artists-join-smart-art-every-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["千+新しいアーティストは、毎年SMART ARTに参加します"])},
    "new-buyers-visit-smart-every-week ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["五千 人以上の新規購入者が毎週 Smart Art を訪問"])},
    "providing-number-one-service-to-clients ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ARTは顧客にno1サービスを提供しています"])},
    "worlds-number-one-art-business-website ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ARTは、クライアントの世界no1アートビジネスウェブサイトにno1サービスを提供しています"])},
    "successfully-working-in-all-asian-countries-and-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのアジア諸国での作業に成功しています。"])},
    "are-you-worried ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["心配してるの?"])},
    "worried-about-searching-for-best-art-piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの場所のための最高のアート作品を探して心配？"])},
    "worried-about-buying-art-online ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートをオンラインで購入することを心配していますか？"])},
    "not-having-trust-on-art-bought-online ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オンラインで購入したアートは信用できません!"])},
    "not-having-proper-knowledge-of-art-business ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートビジネスの適切な知識を持っていませんか？"])},
    "looking-for-earning-from-art-business ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートビジネスからの収益をお探しですか？"])},
    "want-to-create-exhibition ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示会を作成したいですか？"])},
    "get-graphical-view-of-daily-uploaded-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎日アップロードされるアートをグラフィカルに表示できます"])},
    "will-get-information-on-art-bought-and-rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入およびレンタルしたアートに関する情報がグラフィカルなビューで表示されます"])},
    "detailed-information-about-the-followed-artists ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォローしているアーティストの詳細情報"])},
    "notifications-and-ending-rental-date-reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知と終了レンタル日リマインダ"])},
    "newly-uploaded-arts-from-followed-artists ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォローしているアーティストから定期的に新しくアップロードされるアート"])},
    "best-offers-regularly ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定期的に最高のオファー"])},
    "discounts-on-special-events-and-reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別なイベントの割引＆イベントのリマインダー"])},
    "smart-calender-dashboard-to-remind-every-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボードのスマートカレンダーで人生のあらゆるイベントを思い出させます"])},
    "best-arts-from-the-world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["世界からの最高の芸術"])},
    "smart-art-is-the-leading-player-of-art-world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアート\nアートの世界のリーディングプレーヤーであります"])},
    "smart-art-have-world-wide-artists-best-arts ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ARTには世界中のアーティストの最高のアートが揃っています"])},
    "more-than-ten-thousand-artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一万人以上のアーティスト"])},
    "hundred-plus-arts-uploads-every-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月百以上のプラスアーツアップロード"])},
    "thousand-plus-new-artists-join-every-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["千+新しいアーティストは、毎年SMART ARTに参加します"])},
    "smart-art-providing-number-one-service-to-clients ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ARTは顧客にno1サービスを提供しています"])},
    "hey-brother ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["こんにちは兄弟"])},
    "any-idea-where-can-get-custom-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムアートをどこで手に入れることができるか考えていましたか？"])},
    "want-to-gift-art-frame-on-birthday ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私は彼女の誕生日にミイラにアートフレームを贈りたい"])},
    "have-searched-a-lot-about-custom-arts ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私はカスタムアートについてたくさん検索しました"])},
    "but-they-are-charging-a-lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しかし、彼らはたくさん充電しています"])},
    "will-you-please-help-me ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私を助けてくれませんか？"])},
    "best-solution-for-your-problem  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["こんにちはな愛\"私は最高のソリューションの問題"])},
    "best-online-platform-for-all-type-of-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["芸術のすべてのタイプのための最高のオンラインプラットフォーム"])},
    "smart-art-provides-custom-art-from-top-artists-at-discount-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ARTはあなたの誕生日、記念日などのような特別なイベントのための割引価格でトップアーティストからのカスタムアートを提供します。."])},
    "order-custom-art-from-top-artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トップアーティストにカスタムアートを注文する"])},
    "order-custom-photo-by-top-photographers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トップフォトグラファーによるカスタム写真を注文する"])},
    "order-custom-design-by-top-designers ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トップデザイナーによるカスタムデザインを注文する"])},
    "digital-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デジタルアート"])},
    "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["両方"])},
    "art-washable ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート-ウォッシャブル"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい。"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ。"])},
    "upload-wash-guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アップロードウォッシュガイド"])},
    "attach-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像添付"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレビュー"])},
    "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#ピース"])},
    "art-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートの説明"])},
    "abc-art ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABCアート"])},
    "apply-own-frame ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["独自のフレームを適用"])},
    "upload-frame-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレーム画像のアップロード"])},
    "art-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートサイズ"])},
    "frame-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームサイズ"])},
    "minimum-years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分年"])},
    "maximum-years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高年"])},
    "international-orders-acceptable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国際注文 受け入れられるか。"])},
    "sale-auction-cost ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売/オークション費用"])},
    "sale-cost-including-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売コスト（フレームを含む）"])},
    "thirty-percent-of-sale-cost-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売費の30％（購入者）"])},
    "publishing-budget-to-custom-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタムオーダーへの予算の公開"])},
    "upload-reference-file \n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参照ファイルのアップロード"])},
    "terms ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用語"])},
    "this-art-not-published-on-other-platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアートは、オークションや販売のための他のオンラインプラットフォームでは公開されていません。"])},
    "same-art-not-uploaded-with-different-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同じアートは、異なる価格帯でアップロードされません。"])},
    "this-art-will-not-be-published-on-other-platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアートは、少なくとも12ヶ月間私たちのプラットフォームで公開されます。 この間、この同じアートは他のオンラインオークションや販売プラットフォームに公開されません。 コンプライアンスに立っていないと、会員とペナルティの禁止につながる可能性があります。"])},
    "i-accept-the-above-terms-to-upload-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアートにアートをアップロードするには、上記の利用規約に同意します"])},
    "place-order-on ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上の順序を置きます"])},
    "open-auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープンオークション"])},
    "search-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティストを検索"])},
    "search-by-art-name-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート名、タイプ、アーティスト名で検索"])},
    "budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予算"])},
    "showing-arts-for-the-budget  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"予算500万円\"のための芸術を表示するには、変更します"])},
    "delivery-in ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["での配信"])},
    "softcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソフトコピー"])},
    "hardcopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハードコピー"])},
    "physical-design-washable ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物理的な設計洗濯できる"])},
    "architecture ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建築"])},
    "interior-design ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インテリアデザイン"])},
    "exterior-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エクステリアデザイン"])},
    "hall-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホールデザイン"])},
    "drawing-room-design ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["応接室のデザイン"])},
    "office-room-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフィスルームのデザイン"])},
    "design-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインの説明"])},
    "design-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインサイズ"])},
    "event-details-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント詳細（割引）"])},
    "upcoming-exhibiton ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今後の展示会"])},
    "art-exhibiton ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["美術展"])},
    "as-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出席者として"])},
    "as-exhibitor ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出展者として"])},
    "physical-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物理料金"])},
    "virtual-fees ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バーチャル料金"])},
    "arts-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["180位アーツが登録"])},
    "last-date-to-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後に登録する日付"])},
    "apply-as-exhibitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出展者として応募する"])},
    "apply as-physical-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物理的な出席者として適用します。"])},
    "apply-as-virtual-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仮想出席者として申請する"])},
    "cancel-your-subscription-as-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出展日の3日前までに、参加者としての購読をキャンセルすることができます"])},
    "arts-allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#200アート可"])},
    "subscribed-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読済みの参加者"])},
    "subscribed-exhibitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録出展者"])},
    "exhibition-category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会カテゴリ"])},
    "exhibition-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示タイプ"])},
    "exhibition-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会の住所"])},
    "organizer-e-mail-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主催者の電子メールID"])},
    "organizer-tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主催者電話"])},
    "virtual-exhibition-online-link-to-join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加する仮想展示会オンラインリンク"])},
    "note-the-link-exhibitors-attendees-by-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注 - リンクは展示会当日に電子メールで出展者と出席者に送信され、展示会当日に展示会で表示されます。"])},
    "exhibition-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展示日"])},
    "exhibitors-subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出展者募集中"])},
    "showing-enteries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1件-10件の30件を表示しています"])},
    "why-smart-art-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["なぜスマートアートクラウドファンディング？"])},
    "smart-crowdfunding-process-of-raising-funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマート クラウドファンディングは、寄付プラットフォームを媒体として使用し、世界中の人々の協力を得て資金を集めるプロセスです。スマート アートのクラウドファンディングはアーティストへの資金提供に役立ちます。スマート クラウドファンディング プラットフォームを介した資金調達は返済の必要がなく、美術学生から上級アーティストまで、誰でも参加できます。私たちのスマートなクラウドファンディングは、アートを学びたい、またはアートから稼ぎたいと考えている世界中の新入生を支援します。私たちのスマートなクラウドファンディングは、問題に直面しているアーティストに影響を与え、動機を与えます。スマート クラウドファンディングの募金活動に参加して、世界中のアーティスト コミュニティを助けましょう。"])},
    "your-art-need-urgent-financial-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの芸術は、死に向かう途中で緊急の財政援助を必要としています"])},
    "raise-money-online-with-free-crowdfunding ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアート無料クラウドファンディングでオンラインでお金を上げる"])},
    "free-fund-raising-platform ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料資金調達プラットフォーム"])},
    "above-lakhs-generous-donors ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["十万ルピー寛大なドナーの上"])},
    "lots-of-successful-fund-raised ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二百以上の資金調達に成功しました"])},
    "fast-fundraising ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファストファンドレイジング"])},
    "donors-from-countries ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\n十か国からの寄付者"])},
    "generous-donors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寛大なドナー"])},
    "fundraisers-created-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功した資金調達者を作成しました"])},
    "fastest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最速百万円"])},
    "raised-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二十四時間で育てられた"])},
    "smart-art-crowdfunding-projects ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートアートクラウドファンディングプロジェクト"])},
    "bunch-of-nature ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自然の束"])},
    "arts-available-for ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["のために利用可能な芸術"])},
    "check-profile ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィールを確認する"])},
    "the-project-consist-of-authentic-art-peices ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このプロジェクトは、自然をテーマにアーティストの姓名によって作成された 三 つのアート作品で構成されています。認証証明書で利用できるアート さよならアーティスト"])},
    "green-heaven ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グリーンヘブン"])},
    "greenery-in-the-nature ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自然の中の緑"])},
    "the-flow-of-river-in-the-nature ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自然の中の川の流れ"])},
    "in-the-heaven  ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天国で"])},
    "cherry-blossom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["桜"])},
    "the-art-shows-the-cherry-blossom ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートは桜を示しています"])},
    "donated ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄付された"])},
    "total-amount-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご請求金額合計"])},
    "smart-art-successful-crowdfunding-projects ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Art の成功したクラウドファンディング プロジェクト"])},
    "crowdfunding-is-successufully-closed ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディング無事終了しました"])},
    "purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入した"])},
    "art-piece ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート作品"])},
    "flow-in-heaven ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天国の流れ"])},
    "art-shows-greenery-in-nature ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートは自然の中の緑を示しています"])},
    "art-completion-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作品完成スケジュール"])},
    "art-normal-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートコンプリションスケジュール"])},
    "art-cost-in-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングでのアートコスト"])},
    "delivery-date   ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お届け日"])},
    "purchase-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ購入"])},
    "payment-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いオプション"])},
    "forums-published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開されたフォーラム"])},
    "members-engaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["従事しているメンバー"])},
    "create-new-forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいフォーラムを作成する"])},
    "forum-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォーラムの所有者"])},
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバー"])},
    "forum-created-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォーラム作成日"])},
    "forum-updated-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォーラム更新日"])},
    "edit-forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォーラムの編集"])},
    "replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返信"])},
    "post-message ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投稿メッセージ"])},
    "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["のように"])},
    "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ノーマル"])},
    "send ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公共"])},
    "Forum-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォーラムタイプ"])},
    "forum-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォーラムタイプ"])},
    "select-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーの選択"])},
    "internal-forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社内フォーラム"])},
    "forum-detailed-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォーラム詳細メッセージ"])},
    "exhibitions-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有名なアーティスト、写真家、デザイナーによるユニークなアートや写真を探索できるのはここだけです。自然、海洋、ハリウッド、ボリウッド、スポーツ、ファッション、野生動物など、さまざまなカテゴリーの芸術を探索する機会が得られます。アート展示会にお越しください。素晴らしいアート コレクションをご覧ください。芸術的な傑作と珍品の間の折衷的な道。スマート アートでは、展示会に物理的にまたは仮想的に参加するオプションを提供します。バーチャル展示会は、その場で展示会に参加できる特別なサービスです。展示会のリアルな雰囲気を感じながら、すべてのアートや製品をすぐにご覧いただけます。バーチャル展示会を閲覧しながら、購入したりレンタルしたりできます。"])},
    "smart-art-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ART は、アート愛好家が世界中でつながり、アートを鑑賞するためのプラットフォームです。 SMART ART は、世界的に有名なアートを販売およびレンタルしています。 SMART ART は、世界中の芸術家にアートを販売するための毎月の展覧会を提供しています。当社は、発展途上国およびアートを愛するすべての国に顧客ベースを持っています。 SMART ARTではあらゆる種類のアートを一度に入手できます。"])},
    "my-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私のクラウドファンディング"])},
    "my-purchased-arts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の購入した芸術"])},
    "funds-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄付された資金"])},
    "funds-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け取った資金"])},
    "pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中の支払い"])},
    "amount-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄付金額"])},
    "arts-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーツ寄贈"])},
    "money-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["節約されたお金"])},
    "as-organizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主催者として"])},
    "mizuho-bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["みずほ銀行"])},
    "not-applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当なし"])},
    "artist-photographer-vendor-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティスト/写真家 / 仕入先/組織"])},
    "decline-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引率を下げる"])},
    "want-to-decline-request-for-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは本当に、あなたは割引の要求を拒否したいですか"])},
    "describe-reason-to-decline-discount-applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用される割引を拒否する理由を説明する"])},
    "id-proof-showing-date-is-not-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供された写真付き身分証明書は、関連する日付を示す書類が有効ではありません"])},
    "order-payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文の支払い状況"])},
    "tracking-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlの追跡"])},
    "tracking-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追跡番号"])},
    "expected-delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お届け予定日"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["やったよ。"])},
    "request-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いを要求する"])},
    "purchase-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入依頼"])},
    "revenue-from-arts-rented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レンタル芸術からの収入"])},
    "received-different-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私は別の項目を受け取りました"])},
    "reason-for-returning-item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品を返品する理由"])},
    "art-rent-session-is-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート-レンタル-セッション-イン-期限切れ"])},
    "extend-rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家賃の延長"])},
    "apply-auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オークションを申し込む"])},
    "deposit-refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保証金の返金"])},
    "cancel-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエストをキャンセルする"])},
    "enter-virtual-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バーチャル展示会に参加"])},
    "art-shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート-シップ"])},
    "my-donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の寄付"])},
    "return-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品可"])},
    "total-expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総費用"])},
    "add-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートを追加"])},
    "edit-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートの編集"])},
    "once-order-placed-with-valid-reason-can-return-product-within": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["順序が置かれれば、正当な理由のために、プロダクトを中戻すことができます"])},
    "days-after-delivery-at-following-cost-owned-by-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイヤーが所有する次の費用の配達の後の幾日。"])},
    "owners-dispatch-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オーナー派遣料金"])},
    "owner-insurance-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オーナー保険料"])},
    "art-already-used-for-order-placement-it-can-not-be-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアートはすでに注文の配置に使用されています。 削除することはできません"])},
    "sports-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スポーツ写真"])},
    "still-life-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["静物写真"])},
    "editorial-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集写真"])},
    "architectural-photography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建築写真"])},
    "add-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームの追加"])},
    "gallery-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギャラリーフレーム"])},
    "modern-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モダンフレーム"])},
    "floating-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フローティングフレーム"])},
    "deep-set-frames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深セットフレーム"])},
    "canvas-prints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンバスプリント"])},
    "tabletop-frames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卓上フレーム"])},
    "photo-holders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォトホルダー"])},
    "add-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインの追加"])},
    "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザイン"])},
    "landscape-architecture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["景観建築"])},
    "industrial-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工業デザイン"])},
    "fashion-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファッションデザイン"])},
    "engineering-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エンジニアリングデザイン"])},
    "software-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソフトウェア設計"])},
    "user-interface-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーインターフェイスデザイン"])},
    "user-experience-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーエクスペリエンスデザイン"])},
    "want-to-delete-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本当に、デザインを削除しますか"])},
    "applied-as-exhibitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出展者として応募"])},
    "applied-as-virtual-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仮想出席者として適用"])},
    "applied-as-physical-attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物理的な出席者として適用される"])},
    "exit-virtual-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exitバーチャル展示会"])},
    "you-can-edit-delete-exhibition-as-organiser-before-ten-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会の開催日の10日前までに、主催者として展覧会を編集/削除することができます"])},
    "design-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザイン展"])},
    "cancel-exhibitor-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出展者の購読をキャンセルする"])},
    "reason-of-cancelling-subscription-for-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会の購読をキャンセルする理由は何ですか？"])},
    "request-to-cancel-subscription-sent-to-superadmin-you-will-be-informed-after-accepting-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会のためのあなたの購読をキャンセルするためのあなたの要求は、スーパー管理者に送信されます。 \n リクエストを受け入れた後に通知されます"])},
    "bhim-upi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビム・ウピ"])},
    "net-banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネットバンキング"])},
    "security-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セキュリティコード"])},
    "request-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングのご依頼"])},
    "request-crowd-funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングの依頼"])},
    "organizer-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主催者名"])},
    "artist-smart-art-profile-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティストスマートアートプロフィールリンク"])},
    "fundraising-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["募金タイトル"])},
    "fundraising-cover-photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["募金カバー写真"])},
    "domestic-delivery-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内配送料"])},
    "art-completion-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート完成日"])},
    "normal-sale-auction-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常販売/オークション費用"])},
    "crowdfunding-sale-auction-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディング販売/オークション費用"])},
    "normal-rental-cost-per-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常のレンタル費用（月額）"])},
    "crowdfunding-rental-cost-per-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングレンタル費用（月額）"])},
    "publishing-crowdfunding-amount-to-sale-auction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売/オークションへのクラウドファンディング金額を公開"])},
    "add-copy-art-piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピーアートペイスを追加"])},
    "add-more-art-piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["より多くの芸術のPeiceを加えて下さい"])},
    "phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "payment-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いの詳細"])},
    "art-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート-ステイタス"])},
    "return-shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品発送"])},
    "return-delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お届けした商品の返品"])},
    "shipping-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["船積みの細部"])},
    "my-purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の購入した"])},
    "my-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の詳細"])},
    "all-details-should-be-same-as-mentioned-in-my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての詳細は私のプロフィールに記載されているものと同じでなければなりません"])},
    "regular-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常の費用"])},
    "crowdfunding-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングコスト"])},
    "revenue-spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["収益支出"])},
    "total-amount-spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計金額の支出"])},
    "projects-donation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロジェクトの寄付"])},
    "total-amount-donated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄付総額"])},
    "donated-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["寄付された製品"])},
    "item-returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返されたアイテム"])},
    "order-requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文はこちらから"])},
    "additional-charges-for-delivery-insurance-will-be-notyfied-soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配達及び保険のための追加料金はあなたが持っている住所に従って加えられます\n 提供＆あなたは支払いのための最終的な購入金額ですぐに通知されます。"])},
    "refund-deposit-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金保証金ポリシー"])},
    "return-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品注文"])},
    "print-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["印刷の詳細"])},
    "renew-rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家賃を更新する"])},
    "order-renew-payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文のお支払い状況の更新"])},
    "sekai-ichi-rental-deposit-payment-refund-details-to-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAI-ICHI賃貸保証金支払い払い戻し詳細を購入者に"])},
    "order-will-be-confirmed-after-artist-publish-delivery-insurance-cost-to-confirm-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文確定後、配送料-保険料をご負担いただきます。"])},
    "artists-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティスト名"])},
    "accpted-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け入れられたアーティスト"])},
    "cancel-virtual-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仮想サブスクリプションのキャンセル"])},
    "cancel-physical-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物理的なサブスクリプションをキャンセルする"])},
    "can-cancel-subscription-as-attendee-before-3-days-of-exhibition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出展日の3日前までに、参加者としての購読をキャンセルすることができます"])},
    "organizer-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主催者の電子メールID"])},
    "central-time-us-canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中央時間（米国とカナダ）"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効にする"])},
    "virtual-attendee-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仮想出席者の料金"])},
    "physical-attendee-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物理的な出席者の料金"])},
    "due-to-some-reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いくつかの理由のために。...."])},
    "un-subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読していない"])},
    "proceed-to-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いに進む"])},
    "my-funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の資金調達"])},
    "my-purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の購入"])},
    "buyer-dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入者ダッシュボード"])},
    "art-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートギャラリー"])},
    "select-category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリーを選択"])},
    "select-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国を選択"])},
    "photo-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真名"])},
    "design-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザイン名"])},
    "welcome-to-art-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートギャラリーへようこそ"])},
    "welcome-to-photo-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真ギャラリーへようこそ"])},
    "welcome-to-frame-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームギャラリーへようこそ"])},
    "welcome-to-design-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザインギャラリーへようこそ"])},
    "dont-worry-hold-our-hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["心配しないで、私たちの手を取ってください"])},
    "get-more-than-you-expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期待以上のものを手に入れましょう"])},
    "customise-your-art-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート注文をカスタマイズ"])},
    "welcome-to-the-exhibitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展覧会へようこそ"])},
    "welcome-to-smart-forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スマートフォーラムへようこそ"])},
    "we-provide-you-best-worldclass-product-with-authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証付きで最高の世界クラスの製品を提供します"])},
    "select-artist-to-create-your-dream-art-piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理想のアート作品を作るアーティストを選んでください"])},
    "raise-fundus-to-help-art-and-art-community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートとアートコミュニティを支援する資金を募る"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証済み"])},
    "select-industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業界を選択"])},
    "select-industry-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業界タイプを選択"])},
    "i-agree-to-all-the-above-terms-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上記のすべての条件に同意します"])},
    "please-enter-old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["古いパスワードを入力してください"])},
    "please-enter-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを入力してください"])},
    "please-enter-confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認用パスワードを入力してください"])},
    "new-password-and-confirm-password-should-be-same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードと確認用パスワードは同じである必要があります"])},
    "email-verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール認証"])},
    "enter-otp-sent-to-your-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールに送信されたOTPを入力してください"])},
    "resend-otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTPを再送"])},
    "forget-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを忘れる"])},
    "please-enter-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください"])},
    "please-enter-valid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なメールアドレスを入力してください"])},
    "payment-amount-to-be-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い金額"])},
    "todays-exchange-rate-applied-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本日の為替レートは1ドルです"])},
    "you-will-be-charge-with-jpy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["円で請求されます"])},
    "amount-to-your-credit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカードへの請求額"])},
    "please-select-payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法を選択してください"])},
    "please-provide-total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計金額を入力してください"])},
    "payment-unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いが失敗しました"])},
    "something-went-wrong-try-after-some-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題が発生しました。しばらくしてから再試行してください"])},
    "payment-successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いが成功しました"])},
    "thank-you-for-your-payment-transaction-processed-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いありがとうございます。取引が正常に処理されました"])},
    "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームに戻る"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセット"])},
    "request-for-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングをリクエスト"])},
    "select-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータスを選択"])},
    "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエスト済み"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否されました"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリア"])},
    "record-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レコードが見つかりません"])},
    "the-crowdfunding-is-successfully-closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングは正常に終了しました"])},
    "the-crowdfunding-is-rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングが拒否されました"])},
    "view-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報を見る"])},
    "cancelled-crowdfunding-request-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングのリクエストを正常にキャンセルしました"])},
    "cancel-order-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文リストをキャンセル"])},
    "view-user-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザープロファイルを表示"])},
    "crowdfunding-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディング情報"])},
    "project-consist-of-3-art-pieces-created-by-artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このプロジェクトは、自然をテーマにしたアーティストのファーストネームとラストネームによって制作された3つのアート作品で構成されています。アーティストによる認証証明書付きで利用可能なアート"])},
    "art-completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート完成"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スケジュール"])},
    "crowd-funding-successfully-closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングが成功裏に終了しました"])},
    "crowd-funding-rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングは拒否されました"])},
    "organiser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主催者"])},
    "art-normal-rental-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アート通常レンタル料金"])},
    "art-rental-cost-in-crowdfunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングにおけるアートレンタル費用"])},
    "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計金額"])},
    "view-download-receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書の表示/ダウンロード"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダウンロード"])},
    "message-successfully-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージが正常に送信されました。"])},
    "edit-art-piece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートピースを編集する"])},
    "select-product-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品タイプを選択してください"])},
    "type-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプフィールドは必須です"])},
    "select-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品の選択"])},
    "name-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前フィールドは必須です。"])},
    "art-completion-date-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートの完成日は必須です。"])},
    "sekai-ichi-will-deduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEKAICHIが控除します"])},
    "deducting-processing-fee-crowd-funds-will-be-transferred-to-the-artist-or-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集まったクラウドファンドの総額と残りの寄付金に対する手数料および手数料は、アーティストまたは団体に送金されます。"])},
    "service-charge-sale-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス料セールが必要です。"])},
    "crowdfunding-sale-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディング販売は必須です"])},
    "crowdfunding-sale-received-amount-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングセール 受け取り金額が必要です。"])},
    "invalid-data-start-date-greater-than-end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なデータです。開始日が終了日より後です"])},
    "invalid-data-form-cost-value-greater-than-to-cost-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なデータです。フォームのコスト値がコスト値より大きい"])},
    "receipt-not-generated-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["領収書はまだ生成されていません。"])},
    "revenue-from-arts-sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売されたアートからの収益"])},
    "event-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント割引"])},
    "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示中"])},
    "enteries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エントリー"])},
    "cancel-request-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルリクエスト一覧"])},
    "crowdfunding-is-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クラウドファンディングがキャンセルされました"])},
    "cancelled-order-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルされた注文一覧"])},
    "funds-return-to-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資金は購入者に返金されます"])},
    "search-by-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種類で検索"])},
    "seller-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売者プロフィール"])},
    "paypal-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPalのURL"])},
    "my-revenue-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の収益詳細"])},
    "automatic-mobile-notifications-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間の自動モバイル通知コスト"])},
    "server-access-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーアクセスキー"])},
    "total-amount-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払総額"])},
    "fill-payment-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い詳細を入力してください"])},
    "membership-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員ステータス"])},
    "provide-industry-sub-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業界のサブタイプを提供してください"])},
    "provide-industry-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["業界タイプを提供してください"])},
    "enter-company-phone-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の電話コードを入力してください"])},
    "enter-telephone-country-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話の国コードを入力してください"])},
    "enter-address-line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所1行目を入力してください"])},
    "please-enter-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市区町村を入力してください"])},
    "please-enter-pin-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号を入力してください"])},
    "please-enter-last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓を入力してください"])},
    "please-enter-first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名を入力してください"])},
    "want-to-delete-bank-passbook-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行通帳の画像を削除してもよろしいですか？"])},
    "want-to-delete-profile-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール画像を削除してもよろしいですか？"])},
    "attach-png-jpg-pdf-file-of-your-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドキュメントのpng、jpg、pdfファイルを添付してください"])},
    "permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永続的"])},
    "additional-changes-for-delivery-insurance-will-be-added-notified-soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご提供いただいた住所に応じて配送料と保険料が追加され、最終的な購入金額がすぐに通知されます。"])},
    "seller-dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売者ダッシュボード"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ライブラリ"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般"])},
    "general-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般設定"])},
    "return-product-within-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品を日以内に返却"])},
    "rented-product-return-extend-window-open-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レンタル製品の返却/営業期間の延長"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
    "confirmation-to-redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リダイレクトの確認"])},
    "you-are-being-redirected-to-buyer-side-want-to-continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入者側アプリケーションにリダイレクトされようとしています。続行しますか？"])},
    "deleted-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除されたリスト"])},
    "scratch-art": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スクラッチアート"])},
    "frame-material-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレーム素材を選択する必要があります。"])},
    "category-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリーを選択する必要があります。"])},
    "sub-category-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブカテゴリーを選択する必要があります。"])},
    "pieces-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ピースフィールドが必要です。"])},
    "your-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの説明..."])},
    "description-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明フィールドが必要です。"])},
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必須"])},
    "frame-vendor-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームベンダーを選択してください"])},
    "art-width-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートの幅フィールドが必要です。"])},
    "select-art-width-unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートの幅の単位を選択してください。"])},
    "height-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高さフィールドが必要です。"])},
    "select-height-unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高さの単位を選択してください。"])},
    "art-height-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートの高さフィールドが必要です。"])},
    "select-art-height-unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アートの高さ単位を選択してください。"])},
    "frame-height-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームの高さフィールドが必要です。"])},
    "select-frame-height-unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームの高さ単位を選択してください。"])},
    "without-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームなし"])},
    "weight-without-frame-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームなしの重量フィールドが必要です。"])},
    "weight-without-frame-unit-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームなしの重量単位フィールドが必要です。"])},
    "with-frame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームあり"])},
    "weight-with-frame-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームありの重量フィールドが必要です。"])},
    "weight-with-frame-unit-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームありの重量単位フィールドが必要です。"])},
    "minimum-months-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低月数フィールドは必須です"])},
    "maximum-months-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大月数フィールドは必須です"])},
    "rent-renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賃貸更新？"])},
    "sale-auction-cost-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売/オークションコストフィールドが必要です。"])},
    "service-charge-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス料フィールドが必要です。"])},
    "you-receive-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたが受け取る金額が必要です。"])},
    "publishing-amount-to-sale-auction-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売/オークションへの公開額が必要です。"])},
    "rental-cost-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賃貸コストが必要です。"])},
    "rental-cost-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賃貸コスト（購入者側）"])},
    "service-charge-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス料が必要です。"])},
    "rental-you-receive-cost-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賃貸収入が必要です。"])},
    "refund-policy-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返金ポリシーが必要です。"])},
    "deposit-cost-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保証金が必要です。"])},
    "file-size-must-be-less-than-10mb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（ファイルサイズは10MB未満である必要があります）"])},
    "upload-file-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルのアップロードが必要です。"])},
    "if-delivery-insurance-charges-are-more-than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送と保険料がこれを超える場合"])},
    "for-international-service-charge-will-include-delivery-and-insurance-charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売コストの国際配送では、配送および保険料を含むサービス料が適用されます。"])},
    "discount-percentage-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引率が必要です。"])},
    "start-date-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始日が必要です。"])},
    "end-date-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日が必要です。"])},
    "terms-conditions-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約が必要です。"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
    "frame-vendor-not-valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フレームベンダーが無効です。"])},
    "you-do-not-have-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知はありません"])},
    "type-to-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索するには入力してください"])},
    "enter-your-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください"])},
    "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なメールアドレス"])},
    "keep-me-logged-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン状態を保つ"])},
    "please-wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お待ちください..."])},
    "no-data-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データが見つかりません"])},
    "add-role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割名を追加"])},
    "enter-role-show-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割表示名を入力してください"])},
    "enter-role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割名を入力してください"])},
    "coming-soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["近日公開..."])},
    "delete-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レコードを削除"])},
    "want-to-delete-user-record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本当にユーザーレコードを削除しますか？"])},
    "view-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーを表示"])},
    "edit-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーを編集"])},
    "select-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["役割を選択"])},
    "generate-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを生成"])},
    "must-change-password-next-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次回は必ずパスワードを変更する必要があります"])},
    "stripe-api-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストライプAPIキー"])},
    "stripe-key-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストライプキー欄は必須です"])},
    "stripe-api-secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストライプAPIシークレット"])},
    "stripe-secret-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストライプシークレット欄は必須です"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行"])},
    "slack-webhook-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack Webhook URL"])},
    "add-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モジュールを追加"])},
    "module-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モジュール名"])},
    "paypal-url-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal URL欄は必須です"])},
    "paypal-id-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal ID欄は必須です"])},
    "enter-return-product-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品返品日数を入力してください"])},
    "enter-rent-return-extend-product-days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レンタル返品/延長日数を入力してください"])},
    "disable-payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いゲートウェイを無効にする"])},
    "do-you-want-to-disable-this-payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この支払いゲートウェイを本当に無効にしますか？この支払いゲートウェイは使用できなくなります。"])},
    "edit-bank-transfer-payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行振込の支払い方法を編集する"])},
    "account-name-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["口座名欄は必須です"])},
    "bank-name-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行名欄は必須です"])},
    "branch-name-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支店名欄は必須です"])},
    "account-type-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["口座種別は必須です"])},
    "account-number-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["口座番号欄は必須です"])},
    "swift-code-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スイフトコード欄は必須です"])},
    "ifsc-code-field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IFSCコード欄は必須です"])},
    "enter-slack-webhook-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack Webhook URLを入力してください"])},
    "no-module-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モジュールが見つかりません"])},
    "select-module-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モジュール名を選択してください"])},
    "enter-slack-channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slackチャンネルを入力してください"])},
    "country-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国コード"])},
    "enter-city-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["都市（検索するには入力してください）"])},
    "please-enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを入力してください"])},
    "password-must-be-min-6-characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは6文字以上でなければなりません"])},
    "enter-company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社名を入力してください"])},
    "please-enter-country-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国コードを入力してください"])},
    "please-enter-company-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の電話番号を入力してください"])},
    "please-accept-return-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品ポリシーを承認してください"])},
    "select-at-least-one-sub-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["少なくとも 1 つのサブ役割タイプを選択してください"])},
    "enter-valid-email-or-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なメールアドレスまたはパスワードを入力してください"])},
    "artist-sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アーティストサインイン"])},
    "sign-in-as-a-buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入者としてサインインする"])},
    "enter-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを入力してください"])},
    "enter-server-access-key-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーアクセスキーIDを入力してください"])},
    "enter-secret-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シークレットキーを入力してください"])},
    "enter-bucket-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バケット名を入力してください"])},
    "enter-server-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サーバーURLを入力してください"])},
    "save-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細を保存する"])},
    "open-in-edit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集モードで開く"])},
    "provide-disk-quota-rental-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ディスククォータレンタル費用を入力してください"])},
    "please-enter-region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リージョンを入力してください"])},
    "successfully-connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続に成功しました"])},
    "connection-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続に失敗しました"])},
    "successfully-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存に成功しました"])},
    "additional-changes-for-delivery-insurance-will-be-notified-later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送と保険の追加料金はご提供いただいた住所に基づいて加算され、最終購入金額が通知されます。"])},
    "sure-want-to-accept-return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品リクエストを承認してもよろしいですか？"])},
    "enter-reason-to-reject-return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品リクエストを拒否する理由を入力してください。"])},
    "rent-return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レント返品リクエスト"])},
    "sure-want-to-accept-rent-return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レント返品リクエストを承認してもよろしいですか？"])},
    "enter-reason-to-reject-rent-return-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レント返品リクエストを拒否する理由を入力してください。"])},
    "returned-delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品済み"])},
    "forfeited-buyer-deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い手の保証金没収"])},
    "forfeited-deposit-to-seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り手への保証金没収"])},
    "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受領済み"])},
    "rent-return-item-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レント返品アイテム受領"])},
    "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベンダー"])},
    "event-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントの詳細"])},
    "rejected-reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否理由"])},
    "discount-will-be-provided-for-event-gift-purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントギフト購入の見積もり価格に割引が適用されます。"])},
    "wait-until-payment-confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いが確認されるまでお待ちください。"])},
    "delivery-photograph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送写真"])},
    "please-return-product-by-paying-courier-delivery-charges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返品配送費用。ご自身で送料を支払って商品を返品してください。"])},
    "photo-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォトギャラリー"])}
  }
}