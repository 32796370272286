import localforage from 'localforage'
import { isEmpty } from 'lodash'
import { setHttpToken, setHttpBaseUrl } from '../../../helpers'

export const addUser = ({ commit, dispatch }, { payload, context }) => {
    let sso_userId;
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/sso-user/create', payload).then((res) => {
        if(res.data.success){
            payload.sso_user_id = res.data.data.id;
            return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/create', payload).then((response) => {
                if(response.data.success == true){
                    commit('SET_ALERT', 'success', { root: true })
                    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                    return response;
                } else {
                    commit('SET_ALERT', 'warning', { root: true })
                    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                    return response;
                }
            }).catch((error) => {
                dispatch("auth/errorHandler",error.response.status,{ root:true });
                commit('SET_ALERT', 'warning', { root: true })
                commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                return false;
            })
        }
        return res;
    }).catch((error) => {
        if(error.response.status == 422){
            let laraErrors = error.response.data.errors; // this should be errors.
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', laraErrors.email, { root: true })
        } else if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        } else {
            dispatch("auth/errorHandler",error.response.status,{ root:true });
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
            return false;
        }
    })
}

export const updateUser = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/sso-user/update-details/' + payload.old_email_id, payload).then((res) => {
        if(res.data.success){
            payload.sso_user_id = payload.sso_user_id;
            return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/create', payload).then((response) => {
                if(response.data.success == true){
                    commit('SET_ALERT', 'success', { root: true })
                    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                    return response;
                } else {
                    commit('SET_ALERT', 'warning', { root: true })
                    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                    return response;
                }
            }).catch((error) => {
                dispatch("auth/errorHandler",error.response.status,{ root:true });
                commit('SET_ALERT', 'warning', { root: true })
                commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                return false;
            })
        }
        return res;
    }).catch((error) => {
        if(error.response.status == 422){
            let laraErrors = error.response.data.errors; // this should be errors.
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', laraErrors.email, { root: true })
        } else if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        } else {
            dispatch("auth/errorHandler",error.response.status,{ root:true });
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
            return false;
        }
    })
}

export const deleteUser = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/delete-user/'+payload.local_user_id).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return false;
    })
}

export const deleteSSOUser = ({context, dispatch},payload) => {
    return window.axios.delete(process.env.VUE_APP_SSO_API_URL + `api/sso-user/delete/` + payload.payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return false;
    })
}

export const getSuperAdmindata = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/get-super-admin-user-data').then((response) => {
        return response.data.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        return false;
    })
}

export const getUsersData = ({ commit, dispatch }, query='') => {
    if(query == ''){
        query = '?perPage=1000';
    }
    let url = process.env.VUE_APP_SMART_ART_API_URL + 'api/users/list'+ query;
    return window.axios.get(url).then((response) => {
        commit('SET_USERS_LIST_DATA', response.data.data)
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
        return false;
    })
};