export const SET_CONTACTUS_LIST = (state, data) => {
	state.contactUsList.data = data.data.data;
    state.contactUsList.links = data.data.links;
	state.contactUsList.meta = {
		current_page: data.data.current_page,
		from: data.data.from,
		to:  data.data.to,
		total: data.data.total,
		perPage: data.data.per_page
	}
}