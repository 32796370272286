export const getSaleOrdersList = ({ commit, dispatch }, query = '') => {
    query = query ? query+'&ready_for=Sale' : '?ready_for=Sale';
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/orders-list-admin'+ query).then((response) => {
        commit('SET_ORDER_LIST_DATA', response.data.data);
        return response.data.data;
    }).catch((error) => {
        console.log('error',error);
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const getOrderDetails = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/orders-details/'+ query.payload.order_id).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log('error',error);
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const getRentOrdersList = ({ commit, dispatch }, query = '') => {
    query = query ? query+'&ready_for=Rent' : '?ready_for=Rent';
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/orders-list-admin'+ query).then((response) => {
        commit('SET_ORDER_LIST_DATA', response.data.data);
        return response.data.data;
    }).catch((error) => {
        console.log('error',error);
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const bankPaymentConfirmation = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/bankPaymentConfirmation',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}

export const adminReceviedBuyerOrder = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-recevied-buyer-order',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const adminReturnReceviedSellerOrder = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-return-recevied-seller-order',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const refundStripeOrderPayment= ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/stripe-refund-order', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const refundOrderPayment= ({ commit, dispatch }, { payload, context }) => {

    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/refund-order-payment', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const orderPayment = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/order-payment-to-seller',payload).then((response) => {
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const retriveStripData = ({ commit }, {payload}) => {
    let query = '?session_id='+payload.session_id+'&type=superadmin';
    if(payload && payload.seller_id && (payload.seller_id != 'undefined')){
        query = query+'&seller_id='+payload.seller_id;
    }
    if(payload && payload.user_id && (payload.user_id != 'undefined')){
        query = query+'&user_id='+payload.user_id;
    }
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL+'api/retrive-stripe-data'+query).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
       commit("SET_ALERT", "warning", { root: true });
       commit("SET_ALERT_MESSAGE", "Something went wrong " + error, { root: true });
       return false;
    });
};
export const depositPayment = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/deposit-payment',payload).then((response) => {
        // commit('SET_ALERT', 'success', { root: true })
        // commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const funcClosed = ({ commit, dispatch }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/change-order-status',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const getRevenueAmounts = ({ commit, dispatch }, {payload, context}) => {
    let seller_id = payload.seller_id;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/getRevenueAmounts/' + seller_id).then((response) => {
        return response.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
